import { useRoute } from 'vue-router'

export function usePhoneNumber () {
  const route = useRoute()
  const query = route.query

  if (query.gp) { return '770-629-9987' }
  if (query.bp) { return '678-884-4623' }
  if (query.mp) { return '770-874-2875' }
  if (query.fp) { return '470-410-7295' }
  if (query.yp) { return '470-660-5739' }
  if (query.ap) { return '470-410-9641' }
  if (query.fly) { return '404-537-4065' }
  if (query.np) { return '470-260-8689' }
  if (query.gdn) { return '470-665-4831' }

  // Default phone number
  return '770-758-6090'
}
